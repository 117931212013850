<template>
  <form-wizard
    @on-complete="onComplete"
    @on-loading="setLoading"
    @on-error="handleErrorMessage"
    class="wizard"
    shape="circle"
    color="#2dabe2"
    error-color="#ff6961"
    title="Nouveau projet"
    :subtitle="subtitle"
    ref="wizard"
  >
    <!-- Form type -->
    <tab-content v-if="enableFastForm" title="Formulaire" icon="ti-settings">
      <label class="tab-content-label">Choisir le type de formulaire : </label>
      <b-form-group class="text-center">
        <b-form-radio-group
          v-model="formType"
          @change="handleFormTypeChange"
          :options="[
            { text: 'Rapide', value: 'fast' },
            { text: 'Complet', value: 'full' }
          ]"
          buttons
          stacked
          button-variant="outline-secondary"
        ></b-form-radio-group>
      </b-form-group>
    </tab-content>
    <!-- Project type -->
    <tab-content
      v-if="projectTypes.length > 1"
      title="Type de projet"
      icon="ti-menu-alt"
    >
      <label class="tab-content-label">Choisir le type de projet : </label>
      <b-form-group class="text-center">
        <b-form-radio-group
          v-model="projectType"
          @change="handleProjectTypeChange"
          :options="projectTypes"
          buttons
          stacked
          button-variant="outline-secondary"
        ></b-form-radio-group>
      </b-form-group>
    </tab-content>
    <!-- Informations client -->
    <tab-content
      title="Info. contact"
      icon="ti-user"
      :before-change="validateClientInfo"
    >
      <label class="tab-content-label">Informations de client : </label>
      <!-- Nom -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Nom *"
        label-for="input-last-name"
      >
        <b-form-input
          v-model="form.lastName"
          id="input-last-name"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Prénom -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Prénom *"
        label-for="input-first-name"
      >
        <b-form-input
          v-model="form.firstName"
          id="input-first-name"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- E-mail -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="E-mail *"
        label-for="input-mail"
      >
        <b-form-input
          v-model="form.email"
          type="email"
          id="input-mail"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Téléphone -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Téléphone *"
        label-for="input-phone"
      >
        <input
          class="input-phone"
          v-model="form.phone"
          id="input-phone"
          size="sm"
          spellcheck="false"
          maxlength="10"
          @input="
            form.phone = form.phone
              .replace(/[^0-9]/g, '')
              .replace(/(\..*)\./g, '$1')
          "
        />
      </b-form-group>
      <!-- Code postal -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Code postal *"
        label-for="input-cp"
      >
        <b-form-input
          v-model="form.codePostal"
          id="input-cp"
          size="sm"
          type="number"
        ></b-form-input>
      </b-form-group>
      <!-- Ville -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Ville *"
        label-for="input-city"
      >
        <b-form-input
          v-model="form.city"
          id="input-city"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Address -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Adresse *"
        label-for="input-address"
      >
        <b-form-input
          v-model="form.address"
          id="input-address"
          size="sm"
        ></b-form-input>
      </b-form-group>
    </tab-content>
    <!-- Informations client de naissance -->
    <tab-content
      v-if="formType === 'full'"
      title="Info. naissance"
      icon="ti-layout-cta-left"
      :before-change="validateClientInfo"
    >
      <label class="tab-content-label">Informations de client : </label>
      <!-- Nom de naissance -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Nom de naissance"
        label-for="input-birth-name"
      >
        <b-form-input
          v-model="form.nom_naissance"
          id="input-last-birth-name"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Date de naissance -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Date de naissance"
        label-for="input-birthday"
      >
        <b-form-input
          v-model="form.date_naissance"
          type="date"
          id="input-birthday"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- + 70 Ans -->
      <b-form-group
        v-if="projectType == 107"
        label-cols="4"
        label-size="sm"
        label="+ 70 Ans"
        label-for="input-70-years"
      >
        <b-form-select
          v-model="form.plus_70_ans"
          :options="[
            { text: 'oui', value: 'OUI' },
            { text: 'non', value: 'NON' }
          ]"
          id="input-70-years"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <!-- Code postal de naissance -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Code postal de naissance"
        label-for="input-birth-postal-code"
      >
        <b-form-input
          v-model="form.cp_naissance"
          id="input-birth-postal-code"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Ville de naissance -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Ville de naissance"
        label-for="input-birth_city"
      >
        <b-form-input
          v-model="form.city_naissance"
          id="input-birth_city"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Pays de naissance -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Pays de naissance"
        label-for="input-birth_country"
      >
        <b-form-input
          v-model="form.pays_naissance"
          id="input-birth_country"
          size="sm"
        ></b-form-input>
      </b-form-group>
    </tab-content>
    <!-- Informations fiscales -->
    <tab-content
      v-if="formType === 'full'"
      title="Info. fiscales"
      icon="ti-money"
      :before-change="validateFiscalInfo"
    >
      <label class="tab-content-label">Informations fiscales : </label>
      <!-- Numéro fiscal -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Numéro fiscal *"
        label-for="input-num-fiscal-1"
      >
        <b-form-input
          v-model="form.tax_number"
          id="input-num-fiscal-1"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Numéro fiscal 2 -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Numéro fiscal 2"
        label-for="input-num-fiscal-2"
      >
        <b-form-input
          v-model="form.tax_number_2"
          id="input-num-fiscal-2"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Ref.fiscal de l'avis -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Ref. fiscal de l'avis *"
        label-for="input-ref-fiscal"
      >
        <b-form-input
          v-model="form.reference_fiscal"
          id="input-ref-fiscal"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Revenu fiscal -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Revenu fiscal *"
        label-for="input-rev-fiscal"
      >
        <b-form-input
          v-model="form.revenu_fiscal"
          id="input-rev-fiscal"
          size="sm"
          type="number"
        ></b-form-input>
      </b-form-group>
      <!-- Année -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Année *"
        label-for="input-fiscal-year"
      >
        <b-form-input
          v-model="form.year"
          id="input-fiscal-year"
          size="sm"
          type="number"
        ></b-form-input>
      </b-form-group>
    </tab-content>
    <!-- Informations projet -->
    <tab-content title="Info. projet" icon="ti-files">
      <label class="tab-content-label">Informations de projet : </label>
      <!-- Adresse des travaux -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Adresse des travaux"
        label-for="input-address-travaux"
      >
        <b-form-input
          v-model="form.address_project"
          id="input-address-travaux"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Occupation (full) -->
      <b-form-group
        v-if="formType === 'full'"
        label-cols="4"
        label-size="sm"
        label="Occupation"
        label-for="input-occupation"
      >
        <b-form-select
          v-model="form.occupation"
          :options="[
            { text: '', value: null },
            { text: 'Locataire', value: 'Locataire' },
            {
              text: 'Propriétaire occupant',
              value: 'Propriétaire_occupant'
            },
            {
              text: 'Propriétaire Bailleur',
              value: 'Propriétaire_Bailleur'
            }
          ]"
          id="input-occupation"
          size="sm"
        ></b-form-select>
      </b-form-group>
    </tab-content>
    <!-- Surfaces  -->
    <tab-content v-if="surfaceTabActive" title="Surfaces" icon="ti-home">
      <label class="tab-content-label">Informations sur les surfaces : </label>
      <template v-if="projectType == 12 || projectType == 46">
        <!-- Soufflé -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface soufflé"
          label-for="input-surface-souffle"
        >
          <b-form-input
            v-model="form.surface_souffle"
            id="input-surface-souffle"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Déroulé -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface déroulé"
          label-for="input-surface-deroule"
        >
          <b-form-input
            v-model="form.surface_deroule"
            id="input-surface-deroule"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Rampant -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface rampant"
          label-for="input-surface-rampant"
        >
          <b-form-input
            v-model="form.surface_rampant"
            id="input-surface-rampant"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Mur -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface mur"
          label-for="input-surface-mur"
        >
          <b-form-input
            v-model="form.surface_mur"
            id="input-surface-mur"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Pignon -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface pignon"
          label-for="input-surface-pignon"
        >
          <b-form-input
            v-model="form.surface_pignon"
            id="input-surface-pignon"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Plafond -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface plafond"
          label-for="input-surface-plafond"
        >
          <b-form-input
            v-model="form.surface_plafond"
            id="input-surface-plafond"
            size="sm"
          ></b-form-input>
        </b-form-group>
        <!-- Vide sanitaire -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Surface vide sanitaire"
          label-for="input-surface-vs"
        >
          <b-form-input
            v-model="form.surface_vide_sanitaire"
            id="input-surface-vs"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <!-- Type de chauffage -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Type de chauffage"
          label-for="input-type-chaffage"
        >
          <b-form-select
            v-model="form.type_heating"
            :options="[
              { text: '', value: null },
              { text: 'Combustible', value: 'Combustible' },
              { text: 'Électricité', value: 'Électricité' }
            ]"
            id="input-type-chaffage"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Accès aux combles -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Accès aux combles"
          label-for="input-access-combles"
        >
          <b-form-select
            v-model="form.acces_combles"
            :options="[
              { text: '', value: null },
              { text: 'Trappe', value: 'Trappe' },
              { text: 'Toit', value: 'Toit' },
              { text: 'Escalier', value: 'Escalier' }
            ]"
            id="input-access-combles"
            size="sm"
          ></b-form-select>
        </b-form-group>
        <!-- Type de plafond -->
        <b-form-group
          label-cols="4"
          label-size="sm"
          label="Type de plafond"
          label-for="input-type_plafond"
        >
          <b-form-select
            v-model="form.type_plafond"
            id="input-type_plafond"
            :options="[
              { text: '', value: null },
              { text: 'Bois', value: 'Bois' },
              { text: 'Brique', value: 'Brique' },
              { text: 'Parpaing', value: 'Parpaing' },
              { text: 'Pierre', value: 'Pierre' },
              { text: 'Placo', value: 'Placo' },
              { text: 'Polystyrene', value: 'Polystyrene' },
              { text: 'Béton', value: 'Béton' }
            ]"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </template>
      <!-- ITI+ -->
      <b-form-group
        v-if="projectType == 103 || projectType == 12"
        label-cols="4"
        label-size="sm"
        label="Surface ITI+"
        label-for="input-surface-iti"
      >
        <b-form-input
          v-model="form.surface_iti"
          id="input-surface-iti"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- PMR -->
      <b-form-group
        v-if="projectType == 107 || projectType == 12"
        label-cols="4"
        label-size="sm"
        label="Surface PMR"
        label-for="input-surface-pmr"
      >
        <b-form-input
          v-model="form.surface_pmr"
          id="input-surface-pmr"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- PAC -->
      <b-form-group
        v-if="projectType == 105 || projectType == 12"
        label-cols="4"
        label-size="sm"
        label="Surface PAC"
        label-for="input-surface-pac"
      >
        <b-form-input
          v-model="form.surface_pac"
          id="input-surface-pac"
          size="sm"
        ></b-form-input>
      </b-form-group>
    </tab-content>
    <!-- Information MPR -->
    <tab-content
      v-if="formType === 'full' && projectType == 107"
      title="Info. PMR"
      icon="ti-folder"
    >
      <label class="tab-content-label">
        Informations PMR
      </label>
      <!-- Dimensions de la baignoire/Douche (Min 1m40 long et 80cm larg) -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Dimensions de la baignoire/douche (Min 1m40 long et 80cm larg)"
        label-for="input-dim-bainoire-douche"
      >
        <b-form-input
          v-model="form.dimentions_baignoire_douche"
          id="input-dim-bainoire-douche"
          type="number"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Hauteur sous plafond salle de bain (min 2m15) -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Hauteur sous plafond salle de bain (Min 2m15)"
        label-for="input-hauteur-sous-plafond"
      >
        <b-form-input
          v-model="form.hauteur_sous_plafond"
          id="input-hauteur-sous-plafond"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Position de la baignoire/douche -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Position de la baignoire/douche"
        label-for="input-position-bainoire-douche"
      >
        <b-form-input
          v-model="form.position_baignoire_douche"
          id="input-position-bainoire-douche"
          size="sm"
        ></b-form-input>
      </b-form-group>
      <!-- Y-a-t-il des éléments sur les murs de la baignoire/douche -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Y-a-t-il des éléments sur les murs de la baignoire/douche"
        label-for="input-elements-murs"
      >
        <b-form-select
          v-model="form.elements_murs"
          :options="['oui', 'non']"
          id="input-elements-murs"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <!-- Fenetre ou lucarne à l'emplacement de la nouvelle douche -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Fenêtre ou lucarne à l'emplacement de la nouvelle douche"
        label-for="input-fenetre-lacurne"
      >
        <b-form-select
          v-model="form.fenetre_lacurne"
          :options="['oui', 'non']"
          id="input-fenetre-lacurne"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <!-- Mur en biais à l'endroit où installer Elmer -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Mur en biais à l'endroit où installer Elmer"
        label-for="input-mur-biais"
      >
        <b-form-select
          v-model="form.mur_biais"
          :options="['oui', 'non']"
          id="input-mur-biais"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <!-- Parquet flottant dans la douche -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Parquet flottant dans la douche"
        label-for="input-parquet-flottant"
      >
        <b-form-select
          v-model="form.parquet_flottant"
          :options="['oui', 'non']"
          id="input-parquet-flottant"
          size="sm"
        ></b-form-select>
      </b-form-group>
      <!-- Dimensions approximatives salle de bain -->
      <b-form-group
        label-cols="4"
        label-size="sm"
        label="Dimensions approximatives salle de bain"
        label-for="input-dimentions-sdb"
      >
        <b-form-input
          v-model="form.dimentions_salle_de_bain"
          id="input-dimentions-sdb"
          size="sm"
        ></b-form-input>
      </b-form-group>
    </tab-content>
    <!-- Notes -->
    <tab-content title="Notes" icon="ti-comment-alt">
      <label class="tab-content-label">
        Vous pouvez ajouter un commentaire ici :
      </label>
      <EEditor ref="eeditor" v-model="commentToAdd" class="mb-3" />
    </tab-content>

    <div class="three-dots-loading" v-if="loadingWizard">
      Chargement en cours
    </div>
    <div v-if="errorMsg">
      <span class="error">{{ errorMsg }}</span>
    </div>
    <b-button variant="outline-secondary" slot="prev" size="sm">
      Précedant
    </b-button>
    <b-button variant="outline-secondary" slot="next" size="sm">
      Suivant
    </b-button>
    <b-button variant="success" slot="finish" size="sm">
      Enregistrer
    </b-button>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { mapActions, mapGetters } from 'vuex'
import EEditor from '../EEditor'
import moment from 'moment'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    FormWizard,
    TabContent,
    EEditor
  },
  props: {
    enableFastForm: {
      default: false
    },
    currentType: {
      required: true
    }
  },
  data() {
    return {
      formType: 'full',
      subtitle: 'Formulaire complet',
      loadingWizard: false,
      errorMsg: null,
      form: {
        // contact
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        codePostal: '',
        city: '',
        address: '',

        nom_naissance: '',
        date_naissance: '',
        // + 70 Ans (PMR)
        plus_70_ans: '',
        cp_naissance: '',
        city_naissance: '',
        pays_naissance: '',

        // fiscal
        tax_number: '',
        tax_number_2: '',
        reference_fiscal: '',
        revenu_fiscal: '',
        year: '',

        // projet
        address_project: '',
        occupation: null,

        // surface
        surface_souffle: '',
        surface_deroule: '',
        surface_rampant: '',
        surface_mur: '',
        surface_pignon: '',
        surface_plafond: '',
        surface_vide_sanitaire: '',

        surface_iti: '',
        surface_pmr: '',
        surface_pac: '',
        type_heating: null,
        acces_combles: null,
        type_plafond: null,

        // info PMR
        dimentions_baignoire_douche: '',
        hauteur_sous_plafond: '',
        position_baignoire_douche: '',
        elements_murs: '',
        fenetre_lacurne: '',
        mur_biais: '',
        parquet_flottant: '',
        dimentions_salle_de_bain: ''
      },
      commentToAdd: '',
      projectType: null
    }
  },
  methods: {
    ...mapActions(['createProject', 'addComment']),
    /**
     * Manage form subtitle
     * move to next tab when form type change
     * reset sepecefic fields
     */
    handleFormTypeChange() {
      if (this.formType === 'fast') {
        this.subtitle = 'Formulaire rapide'
      } else {
        this.subtitle = 'Formulaire complet'
      }
      this.$refs.wizard.nextTab()
      this.resetFormType()
    },
    /**
     * move to next step when project type change
     * reset specefic fields
     */
    handleProjectTypeChange() {
      this.$refs.wizard.nextTab()
      this.resetProjectType()
    },
    /**
     * format form data by form type and project type
     *  and create project
     */
    async onComplete() {
      this.loadingWizard = true

      // minimal setup for project
      const project = {
        title: `${this.form.firstName} ${this.form.lastName}`,
        dtype_id: this.projectType,

        // copy address info from contact
        zipCode: this.form.codePostal,
        city: this.form.city,
        adresse: this.form.address_project
          ? this.form.address_project
          : this.form.address
      }

      // minimal setup for contact
      const contact = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        emails: [{ email: this.form.email, typeE: '' }],
        phones: [{ number: this.form.phone, typeP: '' }],
        zipCode: this.form.codePostal,
        city: this.form.city,
        adresse: this.form.address
      }

      // full form additional setup
      if (this.formType === 'full') {
        // *** project ***
        project.occupation = this.form.occupation
        // projectType = ISO || ITE
        if (this.projectType == 12 || this.projectType == 46) {
          project.surface_souffle = this.form.surface_souffle
          project.surface_deroule = this.form.surface_deroule
          project.surface_rampant = this.form.surface_rampant
          project.surface_mur = this.form.surface_mur
          project.surface_pignon = this.form.surface_pignon
          project.surface_plafond = this.form.surface_plafond
          project.surface_vide_sanitaire = this.form.surface_vide_sanitaire
          project.type_heating = this.form.type_heating
          project.acces_combles = this.form.acces_combles
          project.type_plafond = this.form.type_plafond
          project.surface_iti = this.form.surface_iti
          project.pmr = this.form.surface_pmr
          project.pac = this.form.surface_pac
        }
        // projectType = ITI+
        if (this.projectType == 103) {
          project.surface_iti = this.form.surface_iti
        }
        // projectType = PAC
        if (this.projectType == 105) {
          project.pac = this.form.surface_pac
        }
        // projectType = PMR
        if (this.projectType == 107) {
          project.pmr = this.form.surface_pmr
        }
        if (this.projectType == 107) {
          // * Dimensions de la baignoire/Douche (Min 1m40 long et 80cm larg)
          project[
            '8af7e9e0b03c19803e0ddb6587b871e73139235e2958c55248'
          ] = this.form.dimentions_baignoire_douche
          // * Hauteur sous plafond salle de bain (min 2m15)
          project[
            'c165dd35468943d1455d9683d3c18cccea73aaf0a80b8cc8f8'
          ] = this.form.hauteur_sous_plafond
          // * Position de la baignoire/douche
          project[
            '133a14187cc07833ef3df50e8d7d65e5aa94cdbb0f25c52d8e'
          ] = this.form.position_baignoire_douche
          // * Y-a-t-il des éléments sur les murs de la baignoire/douche
          project[
            'b023cc6a92d4b16d97a57f6c2c65d546113782daa6ed97cac9'
          ] = this.form.elements_murs
          // * Fenetre ou lucarne à l'emplacement de la nouvelle douche
          project[
            '4c63a4607ed6536cdf2a55c3fd2ce2d57657a4dd1ebc15d329'
          ] = this.form.fenetre_lacurne
          // * Mur en biais à l'endroit où installer Elmer
          project[
            'f1b082796ba5a7b35629e7021007c0462071a7c70ee02dce67'
          ] = this.form.mur_biais
          // Parquet flottant dans la douche
          project[
            '1a8e02276fc3e3ea29613af1e80e388542dd5f0df7c1f195e2'
          ] = this.form.parquet_flottant
          // * Dimensions approximatives salle de bain
          project[
            '68da10e543bff7e307477e540aa44b04834c208ce11da3736d'
          ] = this.form.dimentions_salle_de_bain
        }

        // *** contact ***
        contact.nom_naissance = this.form.nom_naissance
        if (this.form.date_naissance) {
          contact.date_naissance = moment(
            this.form.date_naissance,
            'YYYY-MM-DD'
          ).format('YYYY-MM-DD HH:mm:ss')
        }
        contact.cp_naissance = this.form.cp_naissance
        contact.city_naissance = this.form.city_naissance
        contact.pays_naissance = this.form.pays_naissance
        contact.tax_number = this.form.tax_number
        contact.tax_number_2 = this.form.tax_number_2
        contact.reference_fiscal_avis = [
          {
            reference_fiscal: this.form.reference_fiscal,
            revenu_fiscal: this.form.revenu_fiscal,
            year: this.form.year
          }
        ]
        // projectType = PMR
        if (this.projectType == 107) {
          // + 70 Ans
          contact['a93f1b82ca1e57b8f47ac33ef23afd046badb6e6441bd316cd'] = [
            this.form.plus_70_ans
          ]
        }
      }

      if (this.currentUser && this.currentUser.details) {
        if (
          this.currentUser.details.visibleDtypes &&
          this.currentUser.details.visibleDtypes.data
        ) {
          const projectTypes = this.currentUser.details.visibleDtypes.data
          const projectTypeConfig = projectTypes.find(
            item => item.dtype_id == this.projectType
          )
          const currentPipeline = this.getPipelinesPipelines.find(
            item => item.type.id == this.projectType
          )
          // add pipeline + stage + substage
          if (projectTypeConfig) {
            project.cpipeline_id = currentPipeline.id
            project.cstage_id = projectTypeConfig.cstage_id
            project.csubstage_id = projectTypeConfig.csubstage_id
          }
        }

        if (
          this.currentUser.details.team &&
          this.currentUser.details.team.data
        ) {
          // add commercial sédentaire
          project.commercial_sedentaire = this.currentUser.details.team.data.owner_id
          // add source
          project.source_id = this.currentUser.details.team.data.source_id
        }

        if (this.currentUser.type == 'user.commercial') {
          // add compagne if commercial
          project.campaign = this.currentUser.details.full_name
        }
      }

      project.contact = contact

      const response = await this.createProject({
        currentType: this.currentType,
        data: project
      })
      this.loadingWizard = false
      if (response) {
        // add comment
        if (this.commentToAdd && this.getProjectsLastCreated) {
          await this.addComment({
            comment: this.commentToAdd,
            project: this.getProjectsLastCreated
          })
        }
        this.$emit('created', this.getProjectsLastCreated)
      }
    },
    setLoading: function(value) {
      this.loadingWizard = value
    },
    handleErrorMessage: function(errorMsg) {
      this.errorMsg = errorMsg
    },

    resetFormType() {
      // naissance
      this.form.nom_naissance = ''
      this.form.date_naissance = ''
      this.form.plus_70_ans = ''
      this.form.cp_naissance = ''
      this.form.city_naissance = ''
      this.form.pays_naissance = ''

      // fiscal
      this.form.tax_number = ''
      this.form.tax_number_2 = ''
      this.form.reference_fiscal = ''
      this.form.revenu_fiscal = ''
      this.form.year = ''

      // projet
      this.form.occupation = null

      // surface
      this.form.surface_souffle = ''
      this.form.surface_deroule = ''
      this.form.surface_rampant = ''
      this.form.surface_mur = ''
      this.form.surface_pignon = ''
      this.form.surface_plafond = ''
      this.form.surface_vide_sanitaire = ''
      this.form.surface_iti = ''
      this.form.surface_pmr = ''
      this.form.surface_pac = ''
      this.form.type_heating = null
      this.form.acces_combles = null
      this.form.type_plafond = null

      // info PMR
      this.form.dimentions_baignoire_douche = ''
      this.form.hauteur_sous_plafond = ''
      this.form.position_baignoire_douche = ''
      this.form.elements_murs = ''
      this.form.fenetre_lacurne = ''
      this.form.mur_biais = ''
      this.form.parquet_flottant = ''
      this.form.dimentions_salle_de_bain = ''
    },
    resetProjectType() {
      // surface
      this.form.surface_souffle = ''
      this.form.surface_deroule = ''
      this.form.surface_rampant = ''
      this.form.surface_mur = ''
      this.form.surface_pignon = ''
      this.form.surface_plafond = ''
      this.form.surface_vide_sanitaire = ''
      this.form.surface_iti = ''
      this.form.surface_pmr = ''
      this.form.surface_pac = ''
      this.form.type_heating = null
      this.form.acces_combles = null
      this.form.type_plafond = null

      // info PMR
      this.form.dimentions_baignoire_douche = ''
      this.form.hauteur_sous_plafond = ''
      this.form.position_baignoire_douche = ''
      this.form.elements_murs = ''
      this.form.fenetre_lacurne = ''
      this.form.mur_biais = ''
      this.form.parquet_flottant = ''
      this.form.dimentions_salle_de_bain = ''
    },

    // Validations
    validateClientInfo: function() {
      return new Promise((resolve, reject) => {
        if (!this.form.lastName) {
          reject('Le champs "Nom" est obligatoire')
        } else if (!this.form.firstName) {
          reject('Le champs "Prénom" est obligatoire')
        } else if (!this.form.email) {
          reject('Le champs "E-mail" est obligatoire')
        } else if (!this.validateEmail(this.form.email)) {
          reject('Le champs "E-mail" est invalide')
        } else if (!this.form.phone) {
          reject('Le champs "Téléphone" est obligatoire')
        } else if (!this.form.codePostal) {
          reject('Le champs "Code postal" est obligatoire')
        }
        // else if (!this.validatePostalCode(this.form.codePostal)) {
        //   reject('Le champs "Code postal" est invalide')
        // }
        else if (!this.form.city) {
          reject('Le champs "Ville" est obligatoire')
        } else {
          resolve(true)
        }
      })
    },
    validateFiscalInfo() {
      return new Promise((resolve, reject) => {
        if (!this.form.tax_number) {
          reject('Le champs "Numéro fiscal" est obligatoire')
        } else if (!this.form.reference_fiscal) {
          reject('Le champs "Ref. fiscal de l\'avis" est obligatoire')
        } else if (!this.form.revenu_fiscal) {
          reject('Le champs "Revenu fiscal" est obligatoire')
        } else if (!this.form.year) {
          reject('Le champs "Année" est obligatoire')
        } else {
          resolve(true)
        }
      })
    },
    // Validations functions
    validateEmail(value) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(value).toLowerCase()
      )
    }
    // validatePostalCode(value) {
    //   return /^([0-9]{5})$/.test(value)
    // }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getProjectsLastCreated',
      'getPipelinesPipelines',
      'currentUser'
    ]),
    projectTypes: function() {
      return this.getProjectsTypes
        .filter(type => ![46, 103, 105, 107, 109].includes(type.id))
        .map(type => ({
          text: type.name,
          value: type.id
        }))
    },
    surfaceTabActive: function() {
      return (
        this.formType == 'full' &&
        (this.projectType == 12 ||
          this.projectType == 46 ||
          this.projectType == 103)
      )
    }
  },
  mounted() {
    if (this.getProjectsTypes && this.getProjectsTypes.length) {
      this.projectType = this.getProjectsTypes[0].id
    }
  }
}
</script>

<style lang="scss">
.wizard {
  .tab-content-label {
    font-weight: 600;
    position: relative;
    &:before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #5e5e5e;
      display: block;
      position: absolute;
      left: -10px;
      top: 8px;
    }
  }
  .input-phone {
    border: 1px solid #ced4da;
    width: 100%;
    height: 32px;
  }
  .error {
    color: #ff6961;
    font-size: 14px;
  }
  .wizard-icon-circle,
  .ProseMirror {
    &:focus {
      outline: unset;
    }
  }
}
</style>
